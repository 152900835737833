<template>
  <g fill="none" fill-rule="evenodd">
    <g fill="#E5472D">
      <g>
        <path
          fill-rule="nonzero"
          d="M7 .284C3.291.284.284 3.291.284 7S3.291 13.716 7 13.716 13.716 10.709 13.716 7 10.709.284 7 .284zm0 1.182c3.057 0 5.534 2.477 5.534 5.534S10.057 12.534 7 12.534 1.466 10.057 1.466 7 3.943 1.466 7 1.466z"
          transform="translate(-20 -575) translate(20 575)"
        />
        <path
          d="M7.766 4.703c0 .424-.343.766-.766.766s-.766-.342-.766-.766c0-.423.343-.766.766-.766s.766.343.766.766"
          transform="translate(-20 -575) translate(20 575)"
        />
        <path
          fill-rule="nonzero"
          d="M7 6.41c.297 0 .542.218.584.503L7.591 7v2.784c0 .326-.265.59-.591.59-.297 0-.542-.218-.584-.503l-.007-.087V7c0-.326.265-.59.591-.59z"
          transform="translate(-20 -575) translate(20 575)"
        />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
